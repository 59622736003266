<template>
  <el-main class="rh-table">
    <h1>Formations continues</h1>
    <app-table
      tablenom="fcontinues"
      :cols="colonnes"
      :items="fcontinues"
      :loadstatus="fcontinuesLoadStatus"
      :deletestatus="fcontinueDeleteStatus"
      :formroutes="fcontFormRoutes"
      :actions="fcontActions"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      idMed: "",
      fcontFormRoutes: {
        edit: { route: "fcontinueedit", params: { idfc: "id" } },
        create: { route: "fcontinuecreate" },
      },
    };
  },
  computed: {
    fcontinuesLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.fc.fcontinuesLoadStatus;
    },
    fcontinueDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.fc.fcontinueDeleteStatus;
    },
    fcontinues() {
      return this.$store.state.fc.fcontinues;
    },
    colonnes() {
      return this.$store.state.fc.colonnes;
    },
    fcontActions() {
      return this.$store.state.fc.actions;
    },
  },
};
</script>
